<template>
  <div class="popup-wraper" @click="onHideModal">
    <div class="popup-overlay" @click.stop>
      <div class="popup-scroll">
        <div class="popup-overlay-title">
          <img alt="img" src="@/assets/img/publish_as_community.svg"/>
          <span class="popup-overlay-title-span">import</span>
        </div>
        <div class="popup-container">
          <div class="info">check fields to replace if item already exist</div>
          <div class="titles-list">
            <div class="title" v-for="t of fields[tab]" :key="t">
              <label class="label">
                <input
                    type="checkbox"
                    name="checked-item"
                    :value="t"
                    v-model="checkedFields"
                    @input="errorMsg = ''"
                    hidden
                />
                <div class="checkbbox">
                  <img
                      alt="img"
                      src="@/assets/img/checking.svg"
                      class="is-checked"
                  />
                </div>
                <span>{{ t }}</span>
              </label>
            </div>
          </div>
        </div>
        <label class="popup-btn" v-show="tab !== 'translations'">
          <div v-show="errorMsg" class="popup-error">{{ errorMsg }}</div>
          <input
              type="file"
              name="inportdata"
              accept="text/csv"
              @change="onImportData"
              hidden
          />
          import table
        </label>
        <div class="popup-close" @click="onHideModal">&times;</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AuthService from "@/services/AuthService";
import "@/assets/css/popups.css";

export default {
  name: "importPopup",
  data() {
    return {
      errorMsg: "",
      fields: {
        templates: [
          "name",
          "rating",
          "status",
          "content",
          "img",
          "categories"
          // "size",
          // "author",
          // "content_manager"
        ],
        images: [
          "name",
          "rating",
          "status",
          "content",
          "copyright",
          "categories"
          // "size",
          // "author",
          // "content_manager"
        ],
        texts: [
          "name",
          "rating",
          "status",
          "content",
          "img",
          "categories",
          // "size",
          // "author",
          // "content_manager"
        ],
        fonts: [
          "name",
          "status",
          "copyright",
          "rating",
          "content"
          // "size",
          // "author"
        ],
        categories: [
          "status",
          "name",
          "position",
          // "author"
        ],
        users: [
          "name",
          "email",
          "avatar",
          "active",
          "role",
          "website",
          "additional_information",
          "country_code",
          "city",
          "confirm_email",
          "limits",
        ],
        pages: [
          // "content",
          // "img",
          "name",
          // "size",
          // "content_manager"
        ]
      },
      checkedFields: [],
      tabsToTablesName: {
        templates: "template",
        images: "rich_image",
        texts: "text",
        fonts: "font",
        categories: "category",
        users: "user"
      }
    };
  },
  computed: {
    ...mapGetters({
      popupsData: "workSpace/popupsData"
    }),
    tab() {
      return this.popupsData?.step;
    }
  },
  methods: {
    async onImportData(e) {
      try {
        this.errorMsg = "";
        if (e.target.files[0] && !e.target.files[0].name.endsWith(".csv")) {
          this.errorMsg = "wrong format";
          return;
        }
        return AuthService.importData({
          fieldsToReplace: this.checkedFields,
          table: this.tabsToTablesName[this.tab],
          csv: e.target.files[0]
        });
      } catch (error) {
        console.log(error);
      }
    },
    onHideModal() {
      this.$emit("hide-modal");
    }
  }
};
</script>

<style scoped>
.popup-overlay {
  max-width: 1335px;
  width: calc(100% - 20px);
  max-height: 762px;
  height: calc(100vh - 90px);
}

.popup-scroll {
  height: 100%;
}

.popup-btn {
  margin-top: auto;
  position: relative;
}

.info {
  text-align: center;
}

.checkbbox {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid black;
  position: relative;
  margin-left: 5px;
}

.is-checked {
  display: none;
  position: absolute;
  top: -8px;
  left: -3px;
  width: 24px;
  height: 24px;
}

.label {
  display: flex;
  margin: 10px 0;
}

.label span {
  margin-left: 7px;
}

input[type="checkbox"]:checked + .checkbbox > .is-checked {
  display: block;
}
</style>
